import { RateType } from "./models/RateType";

export class ByteConversion {
    public static readonly SIZES_BIT: string[] = ["b", "Kb", "Mb", "Gb", "Tb"];

    public static readonly SIZES_BYTE: string[] = ["B", "KB", "MB", "GB", "TB"];

    public static readonly SIZES_BIT_RATE: string[] = ["bps", "Kbps", "Mbps", "Gbps", "Tbps"];

    public static readonly SIZES_BYTE_RATE: string[] = ["Bps", "KBps", "MBps", "GBps", "TBps"];

    public static bytesToFormatted = (bytes: number, decimalPlaces: number = 0, rateType: RateType = RateType.BYTE): string => {
        const decDiv = Math.pow(10.0, decimalPlaces);
        let rates: string[];
        switch (rateType) {
            case RateType.BIT:
                rates = ByteConversion.SIZES_BIT;
                break;
            case RateType.BYTE:
                rates = ByteConversion.SIZES_BYTE;
                break;
            case RateType.BIT_RATE:
                rates = ByteConversion.SIZES_BIT_RATE;
                break;
            case RateType.BYTE_RATE:
                rates = ByteConversion.SIZES_BYTE_RATE;
                break;
            default:
                return "";
        }

        if (bytes === 0) {
            return `0 ${rates[0]}`;
        }

        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return `${(Math.round(bytes / Math.pow(1024, i) * decDiv) / decDiv).toFixed(decimalPlaces)} ${rates[i]}`;
    };

    public static formattedToBytes = (formattedStr: string): number => {
        const split = formattedStr.split(" "),
            nums = parseFloat(split[0]),
            rate = split[1],
            arr = [ByteConversion.SIZES_BIT, ByteConversion.SIZES_BYTE, ByteConversion.SIZES_BIT_RATE, ByteConversion.SIZES_BYTE_RATE];
        let power = -1;

        for (const rateIncrements of arr) {
            const index = rateIncrements.indexOf(rate);
            if (index > -1) {
                power = index;
                break;
            }
        }

        if (power === -1) {
            return 0;
        }

        return Math.pow(1024, power) * nums;
    };
}
