export class VArchive {
    private _current: unknown = null;

    public get current(): unknown {
        return this._current;
    }

    public set current(value: unknown) {
        if (value == null) {
            return;
        }
        if (this._current != null) {
            throw new Error("Cannot set page's current, conflicting instances encountered");
        }
        this._current = value;
    }

    public readonly isIpad: boolean = (Boolean(/mac/.exec(navigator.userAgent.toLowerCase())) && navigator.maxTouchPoints > 0);

    public readonly isMobile: boolean = Boolean(/android|webos|iphone|ipad|ipod|blackberry|windows phone|iemobile|wpdesktop|bb\d*|tizen|mobile|phone|tablet|palm|symbian|sailfish/.exec(navigator.userAgent.toLowerCase())) || this.isIpad;

    public constructor() {

    }
}
