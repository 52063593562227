import { VelocityData } from "./models/VelocityData";
import { VArchive as _VArchive } from "./VArchive";
import { ServiceWorkerHelper } from "./ServiceWorkerHelper";
import { Snackbar } from "./Snackbar";
import { ByteConversion } from "./ByteConversion";

/* eslint-disable @typescript-eslint/naming-convention */
declare global {
    interface Window {
        VArchive: _VArchive;
        ServiceWorkerHelper: typeof ServiceWorkerHelper;
        Snackbar: typeof Snackbar;
        ByteConversion: typeof ByteConversion;
        lozadObserver: lozad.Observer;
    }
}
/* eslint-enable @typescript-eslint/naming-convention */

window.VArchive = new _VArchive();
window.ServiceWorkerHelper = ServiceWorkerHelper;
window.Snackbar = Snackbar;
window.ByteConversion = ByteConversion;

export class Main {
    public isDrawerEnabled: boolean = false;

    private static singletonInit: boolean = false;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public static readonly instance: Main = new Main();

    public isInStandaloneMode = (): boolean => (window.matchMedia("(display-mode: standalone)").matches) || (window.navigator["standalone"] as boolean) || document.referrer.includes("android-app://");

    public constructor() {
        if (Main.singletonInit) {
            return;
        }
        Main.singletonInit = true;
        $(() => {
            this.init();
        });
    }

    private init = () => {
        $("body").attr("data-mobile", `${VArchive.isMobile}`);

        $("#header-nav-button, #drawer-overlay").on("click", () => {
            if (window.innerWidth >= 1100 && window.location.pathname !== "/watch" && window.location.pathname !== "/live" && window.location.pathname !== "/saved") {
                return;
            }

            const bodyElem = $("body");
            if (bodyElem.attr("data-search") === "false") {
                this.isDrawerEnabled = !this.isDrawerEnabled;
                bodyElem.attr("data-drawer", this.isDrawerEnabled.toString());
            } else {
                bodyElem.attr("data-search", "false");
            }
        });

        $("#login-signin-button").on("click", () => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            window["showLoginScreen"]();
        });

        // START OF TOUCH DRAWER
        const drawerElem = document.querySelector("#drawer"),
            drawerWidth = 275,
            targetVelocity = 1.5,
            velocityData: VelocityData = { initial: { x: 0, time: 0 }, final: { x: 0, time: 0 } };

        let canMoveDrawer = false,
            initialX = -1;
        $(window).on("touchstart", (e) => {
            const event = e.originalEvent,
                value = event.touches[0].clientX - drawerElem.getBoundingClientRect().right;
            if ($(e.target).parents("#drawer").length < 1 && (value <= 20 || $("body").attr("data-drawer") === "true")) {
                canMoveDrawer = true;
                initialX = event.touches[0].clientX - drawerElem.getBoundingClientRect().right;
                velocityData.final = {
                    x: event.touches[0].clientX,
                    time: e.timeStamp
                };
                e.preventDefault();
            } else {
                canMoveDrawer = false;
            }
        }).on("touchmove", (e) => {
            if (canMoveDrawer) {
                const currentX = (e.originalEvent).touches[0].clientX,
                    value = currentX < initialX ? -drawerWidth : ((currentX - initialX - drawerWidth) > 0 ? 0 : (currentX - initialX - drawerWidth));
                $("#drawer").css({
                    transition: "initial",
                    marginLeft: `${value}px`
                });
                $("#drawer-overlay").css({
                    transition: "initial",
                    opacity: -((drawerWidth + value) / -275)
                });

                velocityData.initial = velocityData.final;
                velocityData.final = {
                    x: currentX,
                    time: e.timeStamp
                };
                e.preventDefault();
            }
        })
            .on("touchend", (e) => {
                if (canMoveDrawer) {
                    const $drawerElem = $("#drawer"),
                        marginLeft = parseInt($drawerElem.css("marginLeft")),
                        velocity = (velocityData.final.x - velocityData.initial.x) / (velocityData.final.time - velocityData.initial.time);

                    if ((marginLeft < -(drawerWidth / 2) && !(velocity > targetVelocity)) || velocity < -targetVelocity) {
                        $("body").attr("data-drawer", "false");
                    } else if (marginLeft >= -(drawerWidth / 2) || velocity > targetVelocity) {
                        $("body").attr("data-drawer", "true");
                    }
                    $drawerElem.css({
                        transition: "",
                        marginLeft: ""
                    });
                    $("#drawer-overlay").css({
                        transition: "",
                        opacity: ""
                    });
                }
                canMoveDrawer = false;
            });
        // START OF LAZY LOADING
        const observer = lozad(".lazyload");
        observer.observe();
        window.lozadObserver = observer;

        document.querySelectorAll("img.lazyload").forEach((e: HTMLImageElement) => {
            addLazyLoadTransition(e);
        });

        /*
         * END OF LAZY LOADING
         * END OF TOUCH DRAWER
         */
        if (this.isInStandaloneMode()) {
            if (!localStorage.getItem("pwa-install")) {
                localStorage.setItem("pwa-install", "1");

                Snackbar.show("Application installed");
            }

            // START OF PROGRESS
            NProgress.configure({ minimum: 0.2 });
            $(window).on("beforeunload", () => {
                NProgress.start();

                let count = 0;
                const interval = setInterval(() => {
                    if (count > 1) {
                        clearInterval(interval);
                    }

                    NProgress.inc();
                    count++;
                }, 300);
            });
            $(window).on("blur", () => {
                NProgress.done();
            });
            // END OF PROGRESS
        }
    };
}
/* eslint-disable @typescript-eslint/naming-convention */
declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Main: typeof Main;
    }
}
/* eslint-enable @typescript-eslint/naming-convention */
window.Main = Main;

function getUrlVars() {
    const vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&#]*)/gi, (m, key, value): string => {
        vars[key] = value;
        return "";
    });
    return vars;
}
window["getUrlVars"] = getUrlVars;

function addLazyLoadTransition(element: HTMLImageElement) {
    if (element instanceof HTMLImageElement) {
        element.addEventListener("load", () => {
            element.classList.add("lazyloaded");
        });
    }
}
window["addLazyLoadTransition"] = addLazyLoadTransition;
