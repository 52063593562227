import { Snackbar_Alignment } from "./models/Snackbar_Alignment";

export class Snackbar {
    private static elem: JQuery;

    // noinspection JSUnusedLocalSymbols
    private static _: unknown = function _() {
        $(() => {
            Snackbar.elem = $("#snackbar");

            $("#snackbar-buttons").on("click", "button", (e) => {
                const index = $(e.currentTarget).index();

                Snackbar.requestCallback?.call(null, index);
            });
        }); return 0;
    }();

    private static currentCallback: number = null;

    private static requestCallback: (number) => void;

    public static show(text: string, timing: number = 2000, align: Snackbar_Alignment = Snackbar_Alignment.CENTER) {
        this.clear();

        $("#snackbar-buttons").attr("data-enabled", "false");
        this.runAnimation(text, align);

        if (timing) {
            this.currentCallback = setTimeout(() => {
                this.close();
            }, timing) as unknown as number;
        }
    }

    public static request(text: string, timeout: number = 0, align: Snackbar_Alignment = Snackbar_Alignment.CENTER, ...actions: string[]): Promise<number|null> {
        this.clear();

        const buttonContainer = $("#snackbar-buttons");
        $("#snackbar-buttons button").remove();
        for (const action of actions) {
            const button = document.createElement("button");
            button.innerText = action;

            buttonContainer.append(button);
        }

        buttonContainer.attr("data-enabled", "true");
        this.runAnimation(text, align);

        return new Promise((resolve) => {
            if (timeout) {
                Snackbar.currentCallback = setTimeout(() => {
                    Snackbar.requestCallback?.call(null, null);
                    this.close();
                }, timeout) as unknown as number;
            }
            this.requestCallback = (index: number) => {
                this.requestCallback = null;
                if (index === -1 || index == null) {
                    resolve(null);
                } else {
                    resolve(index);
                    this.close();
                }
            };
        });
    }

    public static close() {
        this.clear();
        this.elem.addClass("fade");
    }

    private static runAnimation = (text: string, align: Snackbar_Alignment) => {
        const elem = Snackbar.elem;
        elem.attr("data-enabled", "false");
        elem.removeClass("fade");

        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    elem.attr("data-enabled", "true");
                    elem.find("span").text(text);
                    elem.attr("data-align", align);
                });
            });
        });
    };

    private static clear() {
        if (this.currentCallback != null) {
            clearTimeout(this.currentCallback);
        }
        this.currentCallback = null;

        if (this.requestCallback != null) {
            this.requestCallback.call(null, null);
        }
        this.requestCallback = null;
    }
}
