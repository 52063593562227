/* eslint-disable */
export class DatabaseManager {
    public static readonly VC_DBNAME: string = "video-cache-db";
    public static readonly VC_VERSION: number = 2;

    public static readonly VCS_METADATA_NAME: string = "metadata";

    public static readonly VCS_CATEMETADATA_NAME: string = "cate-metadata";
    public static readonly VCS_CATEMETADATA_KEY_UID: string = "uid";
    public static readonly VCS_CATEMETADATA_KEY_CATEID: string = "cateid";

    public static readonly VCS_IMAGE_NAME: string = "image";
    public static readonly VCS_IMAGE_KEY_UID: string = "uid";
    public static readonly VCS_IMAGE_KEY_CHILDUID: string = "childuid";
    public static readonly VCS_IMAGE_KEY_TYPE: string = "type";

    public static readonly VCS_VIDEO_NAME: string = "video";
    public static readonly VCS_VIDEO_KEY_UID: string = "uid";
    public static readonly VCS_VIDEO_KEY_CHILDUID: string = "childuid";

    public static readonly VCS_SUBTITLE_NAME: string = "subtitle";
    public static readonly VCS_SUBTITLE_KEY_UID: string = "uid";
    public static readonly VCS_SUBTITLE_KEY_CHILDUID: string = "childuid";

    private static _instance?: DatabaseManager = null;
    static get instance(): DatabaseManager {
        if(this._instance == null)
            this._instance = new DatabaseManager();
        return this._instance;
    }

    public get ready(): boolean {
        return this._database != null;
    }

    private _database: IDBDatabase = null;
    public get database(): IDBDatabase {
        return this._database;
    }

    private _onReady: () => void = null;
    public set onReady(funct: () => void) {
        if (this.ready) {
            funct.call(this);
        } else {
            this._onReady = funct;
        }
    }

    constructor() {
        this.init();
    }

    private init() {
        let vcRequest: IDBOpenDBRequest = indexedDB.open(DatabaseManager.VC_DBNAME, DatabaseManager.VC_VERSION);

        vcRequest.onsuccess = (e) => {
            let db: IDBDatabase = e.target["result"] as IDBDatabase;

            this._database = db;

            this._onReady?.call(this);
        };

        vcRequest.onerror = (e) => {
            console.trace(e);
        };

        vcRequest.onupgradeneeded = (e) => {
            let db: IDBDatabase = e.target["result"] as IDBDatabase;

            if (!db.objectStoreNames.contains(DatabaseManager.VCS_METADATA_NAME)) {
                db.createObjectStore(DatabaseManager.VCS_METADATA_NAME, {});
            }

            if (!db.objectStoreNames.contains(DatabaseManager.VCS_CATEMETADATA_NAME)) {
                db.createObjectStore(DatabaseManager.VCS_CATEMETADATA_NAME, {keyPath: [DatabaseManager.VCS_CATEMETADATA_KEY_UID, DatabaseManager.VCS_CATEMETADATA_KEY_CATEID]});
            }

            if (!db.objectStoreNames.contains(DatabaseManager.VCS_IMAGE_NAME)) {
                db.createObjectStore(DatabaseManager.VCS_IMAGE_NAME, {keyPath: [DatabaseManager.VCS_IMAGE_KEY_UID, DatabaseManager.VCS_IMAGE_KEY_CHILDUID, DatabaseManager.VCS_IMAGE_KEY_TYPE]});
            }

            if (!db.objectStoreNames.contains(DatabaseManager.VCS_VIDEO_NAME)) {
                db.createObjectStore(DatabaseManager.VCS_VIDEO_NAME, {keyPath: [DatabaseManager.VCS_VIDEO_KEY_UID, DatabaseManager.VCS_VIDEO_KEY_CHILDUID]});
            }

            if (!db.objectStoreNames.contains(DatabaseManager.VCS_SUBTITLE_NAME)) {
                db.createObjectStore(DatabaseManager.VCS_SUBTITLE_NAME, {keyPath: [DatabaseManager.VCS_SUBTITLE_KEY_UID, DatabaseManager.VCS_SUBTITLE_KEY_CHILDUID]});
            }
        };
    };

    private getObjectStore(storeName: string): IDBObjectStore {
        const transaction = this.database.transaction([storeName], "readwrite");
        return transaction.objectStore(storeName) as IDBObjectStore;
    }

    public add(storeName: string, value: unknown, key: IDBValidKey = undefined): Promise<void> {
        return this.performRequest(storeName, "add", value, key);
    }

    public delete(storeName: string, key: IDBValidKey | IDBKeyRange): Promise<undefined> {
        return this.performRequest(storeName, "delete", key)
    }

    public get(storeName: string, key: IDBValidKey | IDBKeyRange): Promise<any> {
        return this.performRequest(storeName, "get", key);
    }

    public getAll(storeName: string, query: IDBValidKey | IDBKeyRange | null = undefined, count: number = undefined) {
        return this.performRequest(storeName, "getAll", query, count);
    }

    private performRequest(storeName: string, functName: string, ...args: any[]): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                const store = this.getObjectStore(storeName),
                    req = store[functName](...args) as IDBRequest;

                req.onerror = (e) => {
                    reject(e);
                };
                req.onsuccess = (e) => {
                    resolve(e.target["result"]);
                };
            } catch (e) {
                reject(e);
            }
        });
    }
}
