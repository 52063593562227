
export class TimeConversion {

    public static toSimpleEta = (time: number, isMs: boolean = false): string => {
        if (!isMs) {
            time *= 1000;
        }

        let value: number,
            suffix: string;
        if (time < 60 * 1000) {
            value = time / 1000;
            suffix = "second";
        } else if (time < 2 * 60 * 60 * 1000) {
            value = time / (60 * 1000);
            suffix = "minute";
        } else {
            value = time / (60 * 60 * 1000);
            suffix = "hour";
        }
        value = Math.ceil(value);

        return `${value} ${suffix}${value === 1 ? "" : "s"}`;
    };
}
