import { DatabaseManager } from "./DatabaseManager";
import { OfflineMetadata_StorageMetadata } from "./models/OfflineMetadata_StorageMetadata";
import { OfflineMetadata } from "./models/OfflineMetadata";
import { OfflineMetadata_Shaka } from "./models/OfflineMetadata_Shaka";
import { OfflineMetadata_StorageCateMetadata } from "./models/OfflineMetadata_StorageCateMetadata";
import { ImageType } from "./models/ImageType";
import { TimeConversion } from "./TimeConversion";
import { RateType } from "./models/RateType";
import { Snackbar_Alignment } from "./models/Snackbar_Alignment";

declare global {
    interface HTMLElement {
        videoMetadata?: { uid: string; childuid: string; data: Blob };
    }
}

export class ServiceWorkerHelper {
    private static readonly MONTHS_ARRAY: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    private databaseManager: DatabaseManager;

    public get isInit(): boolean {
        return this.databaseManager?.ready;
    }

    private currentXhr: XMLHttpRequest = null;

    private currentTrackSelection?: (number) => void = null;

    private static singletonInit: boolean = false;

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public static readonly instance: ServiceWorkerHelper = new ServiceWorkerHelper();

    public constructor() {
        if (ServiceWorkerHelper.singletonInit) {
            return;
        }
        ServiceWorkerHelper.singletonInit = true;
        document.addEventListener("DOMContentLoaded", () => {
            if ("serviceWorker" in navigator) {
                this.init();
            }
        });
    }

    private init = () => {
        this.databaseManager = new DatabaseManager();

        this.databaseManager.onReady = () => {
            this.initPage();
        };
    };

    private initPage = () => {
        if (window.location.pathname.startsWith("/saved")) {
            const urlVars = getUrlVars(),
                uid = urlVars["id"],
                childUid = urlVars["cateid"] ? urlVars["cateid"] : "";

            $("#header-search").hide();
            $("#header-icon-search").hide();
            this.initShaka();
            this.initSidebar();

            if (typeof uid == "undefined") {
                // ignored
            } else {
                this.databaseManager.get(DatabaseManager.VCS_METADATA_NAME, uid).then((vinfo: OfflineMetadata_StorageMetadata) => {
                    let posterLoaded = false,
                        backdropLoaded = false;
                    if (vinfo) {
                        this.databaseManager.get(DatabaseManager.VCS_IMAGE_NAME, [uid, "", "poster"]).then((imageData) => {
                            if (imageData) {
                                const blobUrl = URL.createObjectURL(imageData.data);
                                $("#video_poster img").attr("src", blobUrl);
                            }

                            posterLoaded = true;
                            if (backdropLoaded) {
                                this.onImagesReady();
                            }
                        });
                        this.databaseManager.get(DatabaseManager.VCS_IMAGE_NAME, [uid, "", "backdrop"]).then((imageData) => {
                            if (imageData) {
                                const blobUrl = URL.createObjectURL(imageData.data);
                                $("#content").append(`<style>#content_bg{background-image: url(${blobUrl});}</style>`);
                            }

                            backdropLoaded = true;
                            if (posterLoaded) {
                                this.onImagesReady();
                            }
                        });

                        $("#video_title").html(vinfo.name);
                        $("#video_descVideo .video_descShort").html(vinfo.description);

                        if (!vinfo.iscate) {
                            document.title = `${vinfo.name} ${document.title}`;

                            $("#video_subtitle").html(`${vinfo.year}`);
                            $("#video_descItem").hide();

                            $("[id=videoData_container]").last().remove();

                            this.modifyVideoMetadataCell("duration", `${vinfo.metadata.runtime} min`);

                            const date = new Date(vinfo.metadata.releaseDate * 1000);
                            this.modifyVideoMetadataCell("release-date", `${ServiceWorkerHelper.MONTHS_ARRAY[date.getMonth()]} ${date.getDate()}${this.getDayOrdinal(date.getDate())} ${date.getFullYear()}`);

                            this.modifyVideoMetadataCell("genre", vinfo.metadata.genre.join(", "));
                            this.modifyVideoMetadataCell("cast", vinfo.metadata.cast.join(", "));
                            this.modifyVideoMetadataCell("director", vinfo.metadata.director);
                            this.modifyVideoMetadataCell("production", vinfo.metadata.production);
                        } else {
                            this.databaseManager.get(DatabaseManager.VCS_CATEMETADATA_NAME, [uid, childUid]).then((cVinfo) => {
                                if (cVinfo) {
                                    let episode = cVinfo.episode;
                                    if (Array.isArray(cVinfo.episode)) {
                                        episode = this.toCommaAmpersandString(cVinfo.episode);
                                    }

                                    const seasonEpisode = `Season ${cVinfo.season} - Episode ${episode}`;
                                    $("#video_subtitle").html(seasonEpisode);
                                    $("#video_descItem .video_name").html(cVinfo.name);
                                    $("#video_descItem .video_descShort").html(cVinfo.description);

                                    document.title = `${vinfo.name} - ${seasonEpisode} - ${document.title}`;

                                    $("[id=videoData_container]").first().remove();

                                    this.modifyVideoMetadataCell("seasons", `${vinfo.metadata.seasons}`);
                                    this.modifyVideoMetadataCell("episodes", `${vinfo.metadata.episodes}`);
                                    this.modifyVideoMetadataCell("genre", vinfo.metadata.genre.join(", "));

                                    const date = new Date(vinfo.metadata.releaseDate * 1000);
                                    this.modifyVideoMetadataCell("premiere-date", `${ServiceWorkerHelper.MONTHS_ARRAY[date.getMonth()]} ${date.getDate()}${this.getDayOrdinal(date.getDate())} ${date.getFullYear()}`);

                                    this.modifyVideoMetadataCell("network", vinfo.metadata.network);
                                } else {
                                    this.redirectToLanding();
                                }
                            });
                        }
                    } else {
                        this.redirectToLanding();
                    }
                });
            }
        } else if (window.location.pathname.startsWith("/watch")) {
            $(".save-dialog-cancel").on("click", () => {
                if (this.currentXhr) {
                    this.currentXhr.abort();
                }

                $("#save-dialog").attr("data-enabled", "false");
            });

            $("#save-dialog-select").on("click", () => {
                const index = $("#save-dialog-choices .save-dialog-choice[data-selected='true']").index();

                this.currentTrackSelection?.call(this, index);
            });
        }

        if (window.location.pathname === "/saved") {
            this.initHomePage();
        }
    };

    private isShakaSupported: boolean = false;

    private shakaStorage: shaka.offline.Storage = null;

    private initShaka = () => {
        shaka.polyfill.installAll();
        this.isShakaSupported = shaka.Player.isBrowserSupported();
        if (this.isShakaSupported) {
            this.shakaStorage = new shaka.offline.Storage();
        }
    };

    private getOfflineShakaList = (): Promise<shaka.extern.StoredContent[]> => {
        return new Promise((resolve) => {
            if (!this.isShakaSupported) {
                return resolve([]);
            }

            this.shakaStorage.list().then((arr) => {
                resolve(arr);
            }).catch(() => {
                resolve([]);
            });
        });
    };

    private initHomePage = () => {
        if (window.location.pathname === "/saved") {
            navigator.storage.estimate()
                .then((storageEstimate) => {
                    const bytesUsed = ByteConversion.bytesToFormatted(storageEstimate.usage, 2),
                        bytesQuota = ByteConversion.bytesToFormatted(storageEstimate.quota, 2);

                    $("#saved-quota")
                        .attr("title", `${Math.floor(storageEstimate.usage / storageEstimate.quota * 100 * 100) / 100}% used`);
                    $("#saved-quota-progress-inner")
                        .css("width", `${storageEstimate.usage / storageEstimate.quota * 100}%`);
                    $("#saved-quota-usage")
                        .text(`${bytesUsed} / ${bytesQuota}`);
                });
        }
    };

    private initSidebar = () => {
        $("#drawer_content").on("click", ".drawer_contentRow_savedDelete", (e) => {
            e.preventDefault();
            const text = (e.currentTarget as HTMLElement).closest(".drawer_contentRow").querySelector("span").innerText;

            Snackbar.request(`Remove ${text}?`, 10000, Snackbar_Alignment.CENTER, "Delete").then((index) => {
                if (index === 0) {
                    const hostElem: HTMLElement = (e.target as HTMLElement).closest(".drawer_contentRow_saved"),
                        videoMetadata = hostElem?.videoMetadata;
                    if (videoMetadata) {
                        let promise = this.databaseManager.delete(DatabaseManager.VCS_VIDEO_NAME, [videoMetadata.uid, videoMetadata.childuid ? videoMetadata.childuid : ""]);

                        if (videoMetadata.childuid) {
                            promise = promise.then(() => this.databaseManager.delete(DatabaseManager.VCS_CATEMETADATA_NAME, [videoMetadata.uid, videoMetadata.childuid]));
                        } else {
                            promise = promise.then(() => this.databaseManager.delete(DatabaseManager.VCS_METADATA_NAME, videoMetadata.uid));
                        }

                        promise
                            .then(() => this.shakaStorage.list())
                            .then((list) => {
                                let innerPromise = Promise.resolve();
                                for (const instance of list) {
                                    const appMetadata = instance.appMetadata as OfflineMetadata_Shaka;
                                    if (appMetadata.id === videoMetadata.uid && appMetadata.cateid === videoMetadata.childuid) {
                                        innerPromise = innerPromise.then(() => this.shakaStorage.remove(instance.offlineUri));
                                    }
                                }
                                return innerPromise;
                            })
                            .then(() => this.databaseManager.delete(DatabaseManager.VCS_IMAGE_NAME, [videoMetadata.uid, videoMetadata.childuid ? videoMetadata.childuid : "", "poster"]))
                            .then(() => this.databaseManager.delete(DatabaseManager.VCS_IMAGE_NAME, [videoMetadata.uid, videoMetadata.childuid ? videoMetadata.childuid : "", "backdrop"]))
                            .then(() => {
                                Snackbar.show(`Deleted ${text}`);
                                $(hostElem).remove();
                                this.initHomePage();
                            });

                    }
                }
            });
        });

        $(".drawer_contentRow_saved:not(#drawer_contentRow-master)").remove();

        $(".drawer_contentRow_parent[href='saved']").find(".drawer_contentRow").addClass("selected");

        this.databaseManager.getAll(DatabaseManager.VCS_METADATA_NAME).then((vinfos: OfflineMetadata_StorageMetadata[]) => {
            this.databaseManager.getAll(DatabaseManager.VCS_CATEMETADATA_NAME).then((cVinfos: OfflineMetadata_StorageCateMetadata[]) => {
                this.databaseManager.getAll(DatabaseManager.VCS_VIDEO_NAME).then((videoDatas: Array<{ data: Blob; uid: string; childuid: string }>) => {
                    this.getOfflineShakaList().then((shakaStore) => {
                        videoDatas.push(...shakaStore.map((instance) => {
                            const appMetadata = instance.appMetadata as OfflineMetadata_Shaka;
                            return { uid: appMetadata.id, childuid: appMetadata.cateid, data: null };
                        }));

                        for (const videoData of videoDatas) {
                            const vinfo = vinfos.filter((fVinfo) => fVinfo.uid === videoData.uid)[0];

                            if (vinfo) {
                                let cVinfo = null;
                                if (vinfo.iscate) {
                                    cVinfo = cVinfos.filter((fCVInfo) => (fCVInfo.uid === videoData.uid && fCVInfo.cateid === videoData.childuid))[0];
                                }

                                if (!vinfo.iscate || (vinfo.iscate && cVinfo)) {
                                    const elem = $("#drawer_contentRow-master").clone();
                                    elem.removeAttr("id");
                                    elem[0].videoMetadata = videoData;

                                    let url,
                                        text;
                                    if (!vinfo.iscate) {
                                        url = `/saved?id=${videoData.uid}`;
                                        text = vinfo.name;
                                    } else {
                                        let episode = cVinfo.episode;
                                        if (Array.isArray(cVinfo.episode)) {
                                            episode = this.toCommaAmpersandString(cVinfo.episode);
                                        }

                                        url = `/saved?id=${videoData.uid}&cateid=${videoData.childuid}`;
                                        text = `${vinfo.name} - Season ${cVinfo.season} Episode ${episode}`;
                                    }

                                    elem.attr("href", url);
                                    elem.find("span").text(text);

                                    const urlVars = getUrlVars();
                                    if (urlVars["id"] === videoData.uid && (typeof urlVars["cateid"] == "undefined" || urlVars["cateid"] === videoData.childuid)) {
                                        elem.find(".drawer_contentRow").addClass("selected");
                                    }

                                    $("#drawer_videoContainer").append(elem);
                                }
                            }
                        }
                    });
                });
            });
        });
    };

    /**
     * Modifies a video metadata cell's contents
     * @param type The video metadata cell type
     * @param content The content to update
     */
    private modifyVideoMetadataCell = (type: string, content: string) => {
        const elem = $(`.videoData_cell[data-type="${type}"] .videoData_cellDesc`);
        elem.text(content);
        elem.attr("title", content);
    };

    /**
     * Ran when all posters/backdrops are loaded
     */
    private onImagesReady = () => {
        const urlVars = getUrlVars(),
            uid = urlVars["id"];
        let childUid = urlVars["cateid"] ? urlVars["cateid"] : "";

        $("#content").css("display", "block");

        const videoElem = document.getElementById("video-main") as HTMLVideoElement;
        this.databaseManager.get(DatabaseManager.VCS_VIDEO_NAME, [uid, childUid]).then((videoData) => {
            if (videoData) {
                const blobUrl = URL.createObjectURL(videoData.data);
                videoElem.src = blobUrl;
                videoElem.querySelector("source").src = blobUrl;
            } else {
                childUid = childUid ? childUid : null;
                this.getOfflineShakaList().then((list) => {
                    const shakaMetadata = list.filter((instance) => {
                        const appMetadata = instance.appMetadata as OfflineMetadata_Shaka;
                        return appMetadata.id === uid && appMetadata.cateid === childUid;
                    })[0];
                    if (shakaMetadata) {
                        // @ts-ignore
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                        VArchive.current.shaka.load(shakaMetadata.offlineUri);
                    } else {
                        this.redirectToLanding();
                    }
                });
            }
        });
        this.databaseManager.get(DatabaseManager.VCS_SUBTITLE_NAME, [uid, childUid]).then((subtitleData) => {
            if (subtitleData) {
                const blobUrl = URL.createObjectURL(subtitleData.data);
                videoElem.querySelector("track").src = blobUrl;
            }
        });
    };

    private redirectToLanding = () => {
        window.location.href = "/saved";
    };

    public videoExists = (uid: string = videoData.uids.id, cateid: string = videoData.uids.cateid): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            this.databaseManager.get(DatabaseManager.VCS_VIDEO_NAME, [uid, cateid ? cateid : ""]).then((data) => {
                if (data) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(() => {
                resolve(false);
            });
        });
    };

    public saveCurrent = (url: string) => {
        if (!this.isInit || !this.isDialogOpen()) {
            return;
        }

        navigator.storage.estimate().then((storageEstimate) => {
            const xhr = new XMLHttpRequest();
            this.currentXhr = xhr;

            xhr.onprogress = (e) => {
                if (e.lengthComputable) {
                    let hasAbort = false;
                    if ((storageEstimate.quota - storageEstimate.usage) < e.total) {
                        xhr.abort();

                        hasAbort = true;
                    }

                    this.updateProgress(storageEstimate, e.loaded, e.total, hasAbort);
                }
            };
            xhr.responseType = "blob";
            xhr.addEventListener("load", () => {
                if (xhr.status === 200) {
                    this.currentXhr = null;

                    const out = { data: xhr.response };

                    out[DatabaseManager.VCS_VIDEO_KEY_UID] = videoData.uids.id;
                    out[DatabaseManager.VCS_VIDEO_KEY_CHILDUID] = videoData.uids.cateid ?
                        videoData.uids.cateid :
                        "";

                    this.setDialogText("Saving video data...");

                    promiseAllSettled(this.databaseManager.delete(DatabaseManager.VCS_VIDEO_NAME, [videoData.uids.id, videoData.uids.cateid ? videoData.uids.cateid : ""])).then(() => {
                        this.databaseManager.add(DatabaseManager.VCS_VIDEO_NAME, out).then(() => {
                            let count = 0;
                            const checkExists = new Promise<void>((resolve, reject) => {
                                    this.databaseManager.get(DatabaseManager.VCS_VIDEO_NAME, [videoData.uids.id, videoData.uids.cateid ? videoData.uids.cateid : ""]).then((videoData) => {
                                        if (videoData) {
                                            resolve();
                                        } else {
                                            reject();
                                        }
                                    }).catch(() => {
                                        reject();
                                    });
                                }),
                                run = () => {
                                    if (count === 50) {
                                        this.setDialogError("Failed to save video data");
                                        return;
                                    }

                                    checkExists.then(() => {
                                        this.onDone();
                                    })
                                        .catch(() => {
                                            count++;
                                            setTimeout(run, 500);
                                        });
                                };

                            run();
                        }).catch((e) => {
                            console.error(e);

                            this.setDialogError("Failed to save video data");
                        });
                    });
                } else {
                    this.setDialogError("Storage format not currently supported");
                }
            });
            xhr.addEventListener("error", () => {
                this.setDialogError("Failed to retrieve video data");
            });

            xhr.open("GET", url, true);
            xhr.send();
        });
    };

    public saveMetadata = (metadata: OfflineMetadata): Promise<void> => {
        return new Promise((resolve) => {
            const saveMetadata = () => {
                this.databaseManager.add(DatabaseManager.VCS_METADATA_NAME, metadata.generateStorageMetadata(), metadata.uid);
            };
            this.databaseManager.delete(DatabaseManager.VCS_METADATA_NAME, metadata.uid)
                .then(() => saveMetadata())
                .catch(() => saveMetadata());

            const poster = metadata.getImage(ImageType.POSTER, false),
                backdrop = metadata.getImage(ImageType.BACKDROP, false);
            if (poster != null) {
                this.addImageToStore(metadata.uid, "", ImageType.POSTER, poster);
            }
            if (backdrop != null) {
                this.addImageToStore(metadata.uid, "", ImageType.BACKDROP, backdrop);
            }

            if (metadata.hasCategory) {
                const cateOut = metadata.generateStorageCateMetadata();

                cateOut[DatabaseManager.VCS_CATEMETADATA_KEY_UID] = metadata.uid;
                cateOut[DatabaseManager.VCS_CATEMETADATA_KEY_CATEID] = metadata.cateUid;

                const saveCateMetadata = () => {
                    this.databaseManager.add(DatabaseManager.VCS_CATEMETADATA_NAME, cateOut);
                };
                this.databaseManager.delete(DatabaseManager.VCS_CATEMETADATA_NAME, [metadata.uid, metadata.cateUid])
                    .then(() => saveCateMetadata())
                    .catch(() => saveCateMetadata());

                const catePoster = metadata.getImage(ImageType.POSTER, true);
                if (catePoster != null) {
                    this.addImageToStore(metadata.uid, metadata.cateUid, ImageType.POSTER, catePoster);
                }
            }

            const saveSubtitle = () => {
                if (metadata.subtitleBlob != null) {
                    const data = {
                        data: metadata.subtitleBlob,
                        [DatabaseManager.VCS_SUBTITLE_KEY_UID]: metadata.uid,
                        [DatabaseManager.VCS_SUBTITLE_KEY_CHILDUID]: metadata.cateUid ? metadata.cateUid : ""
                    };

                    this.databaseManager.add(DatabaseManager.VCS_SUBTITLE_NAME, data);
                }
            };
            this.databaseManager.delete(DatabaseManager.VCS_SUBTITLE_NAME, [metadata.uid, metadata.cateUid ? metadata.cateUid : ""])
                .then(() => saveSubtitle())
                .catch(() => saveSubtitle());

            resolve();
        });
    };

    public onDone = () => {
        $("#save-dialog")
            .attr("data-status", "finished");
        this.setDialogText("Finished");
        $("#save-dialog-watch")
            .attr("href", `saved?id=${videoData.uids.id}${videoData.uids.cateid ?
                `&cateid=${videoData.uids.cateid}` :
                ""}`);
    };

    /**
     * Sets the save dialog's text
     * @param text The text to display
     */
    public setDialogText = (text: string) => {
        const content = $("#save-dialog").attr("data-status") === "choice" ? "#save-dialog-choice" : "#save-dialog-default";
        $(`${content} .save-dialog-subtext`).html(text);
    };

    /**
     * Sets the progress of the save dialog
     * @param progress The progress (in percent, ex: 12.3, 100.0)
     */
    public setDialogProgress = (progress: number) => {
        $("#save-dialog-progress-inner").css("width", `${progress}%`);
    };

    private previousValues: { bytes: number; progress: number; time: number; progressRates: number[]; bitrates: number[] } = {
        bytes: 0,
        progress: 0,
        time: new Date().getTime(),
        progressRates: [],
        bitrates: []
    };

    public updateProgress = (storageEstimate: StorageEstimate, bytesDownloaded: number, bytesTotal: number, aborted: boolean = false) => {
        const isTotalProgress = bytesTotal <= 1,
            progress = !isTotalProgress ? (bytesDownloaded / bytesTotal * 100) : bytesTotal * 100,
            diff = bytesDownloaded - this.previousValues.bytes,
            progressDiff = progress - this.previousValues.progress,
            timeDiff = (new Date().getTime() - this.previousValues.time) / 1000;
        this.previousValues.bytes = bytesDownloaded;
        this.previousValues.progress = progress;
        this.previousValues.time = new Date().getTime();

        const currentRate = diff * 8 / timeDiff,
            currentProgressRate = progressDiff / timeDiff;

        if (this.previousValues.bitrates.length >= 50) {
            this.previousValues.bitrates.shift();
            this.previousValues.progressRates.shift();
        }
        this.previousValues.bitrates.push(currentRate);
        this.previousValues.progressRates.push(currentProgressRate);

        let bitrateOut = 0,
            progressRateOut = 0;
        for (let i = 0; i < this.previousValues.bitrates.length; i++) {
            bitrateOut += this.previousValues.bitrates[i];
            progressRateOut += this.previousValues.progressRates[i];
        }
        bitrateOut /= this.previousValues.bitrates.length;
        progressRateOut /= this.previousValues.progressRates.length;

        if (aborted) {
            bytesDownloaded = 0;
            bitrateOut = 0;
            progressRateOut = 0;
        }

        let progressText = `Downloading ${(progress).toFixed(1)}%<br>${TimeConversion.toSimpleEta((100 - progress) / progressRateOut)} left`;
        const progressBarText = `${ByteConversion.bytesToFormatted(bytesDownloaded, 2)}${!isTotalProgress ? ` / ${ByteConversion.bytesToFormatted(bytesTotal, 2)}` : ""}<br>@ ${ByteConversion.bytesToFormatted(bitrateOut, 1, RateType.BIT_RATE)}`;

        if (aborted) {
            progressText = `Insufficient space available<br><br>Space left: ${ByteConversion.bytesToFormatted(storageEstimate.quota - storageEstimate.usage, 2)}<br>Space needed: ${ByteConversion.bytesToFormatted(bytesTotal, 2)}`;
        }

        $("#save-dialog")
            .attr("data-enabled", "true")
            .attr("data-status", "downloading");
        this.setDialogProgress(progress);
        this.setDialogText(progressText);
        $("#save-dialog-progress-text").html(progressBarText);
    };

    /**
     * Returns if the dialog is open
     */
    private isDialogOpen = (): boolean => $("#save-dialog").attr("data-enabled") === "true";

    /**
     * Displays a dialog error
     * @param text The text to display
     */
    public setDialogError = (text: string) => {
        $("#save-dialog").attr("data-status", "failed");
        this.setDialogText(text);
    };

    /**
     * Adds an image to the indexed DB
     * @param uid The video's UID
     * @param childUid The child's UID if exists, or "" if not
     * @param type The type ("poster" or "backdrop")
     * @param url The image's URL
     */
    private addImageToStore = (uid: string, childUid: string, type: ImageType, url: string) => {
        this.retrieveImageAsBlob(url).then((blob) => {
            const out = { data: blob };

            out[DatabaseManager.VCS_IMAGE_KEY_UID] = uid;
            out[DatabaseManager.VCS_IMAGE_KEY_CHILDUID] = childUid;
            out[DatabaseManager.VCS_IMAGE_KEY_TYPE] = type;

            const callback = () => {
                this.databaseManager.add(DatabaseManager.VCS_IMAGE_NAME, out);
            };
            this.databaseManager.delete(DatabaseManager.VCS_IMAGE_NAME, [uid, childUid, type])
                .then(() => callback())
                .catch(() => callback());
        });
    };

    /**
     * Creates an XHR request to a URL to retrieve an image as a blob
     * @param url
     */
    private retrieveImageAsBlob = (url: string): Promise<Blob> => {
        return new Promise<Blob>((resolve, reject) => {
            const xhr = new XMLHttpRequest();

            xhr.open("GET", url, true);
            xhr.responseType = "blob";

            xhr.addEventListener("load", () => {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                }
            });

            xhr.send();
        });
    };

    private getDayOrdinal = (day: number): string => {
        if (day > 3 && day < 21) {
            return "th";
        }
        switch (day % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    };

    private toCommaAmpersandString = (arr: string[]): string => {
        let out = arr[0];
        for (let i = 1; i < arr.length; i++) {
            if (i !== arr.length - 1) {
                out += ` , ${arr[i]}`;
            } else {
                out += ` & ${arr[i]}`;
            }
        }
        return out;
    };
}

function promiseAllSettled(...promises: Array<Promise<unknown>>) {
    promises = promises.map((p) => Promise.resolve(p)
        .then((val) => ({ status: "fulfilled", value: val }),
            (err) => ({ status: "rejected", reason: err })));
    return Promise.all(promises);
}
